<template>
    <div class="row">
        <a name="support_team"></a>
        <div class="col-6">
            <img src="@/assets/00001.png" class="img-fluid" />
        </div>
        <div class="col-6 text-center-div">
            <div class="row text-start">
                <div class="col-12"><h2 class="by-w-size"><img src="@/assets/12.png" class="icon-by-size"/>ราคาถูก สุดคุ้ม</h2></div>
                <div class="col-12"><h2 class="by-w-size"><img src="@/assets/12.png" class="icon-by-size"/>รวดเร็ว ทันใจ</h2></div>
                <div class="col-12"><h2 class="by-w-size"><img src="@/assets/12.png" class="icon-by-size"/>ขนส่งปลอดภัย</h2></div>
                <div class="col-12"><h2 class="by-w-size"><img src="@/assets/12.png" class="icon-by-size"/>บริการระดับมืออาชีพ</h2></div>
                <div class="mt-3 col-12"><h2 class="by-w-size text-success">(ยินดีให้คำปรึกษาฟรี)</h2></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SupportTeam",
    data() {
        return {
            loaded: false,
        };
    },
    mounted() {

    },
    watch: {

    },
}
</script>