<template>
    <div class="row">
        <a name="support_team"></a>
        <div class="col-6 text-center-div">
            <div class="row text-end">
                <div class="col-12"><h2 class="by-w-size">บริการจัดส่งตรงถึงหน้าบ้าน  ทั่วไทย </h2></div>
                <div class="col-12"><h2 class="by-w-size"><img src="@/assets/10.png" class="icon-by-size"/>บริการจัดส่งกรุงเทพฯ และปริมณฑล</h2></div>
                <div class="col-12"><h2 class="by-w-size">บริการจัดส่ง ขนส่งเอกชนทั่วไทย</h2></div>
                <div class="col-12"><h2 class="by-w-size">บริการเก็บค่าขนส่งปลายทาง</h2></div>
                <div class="col-12"><h2 class="by-w-size s text-warning">(เงื่อนไขเป็นไปตามที่บริษัทกำหนด)</h2></div>
            </div>
        </div>
        <div class="col-6">
            <img src="@/assets/002.png" class="img-fluid" />
        </div>
    </div>
</template>

<script>
export default {
    name: "TransportWay",
    data() {
        return {
            loaded: false,
        };
    },
    mounted() {

    },
    watch: {

    },
}
</script>