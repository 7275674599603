import Vue from 'vue'
import App from './App.vue'

import '@/assets/css/bootstrap.min.css';
import * as bootstrap from 'bootstrap';
import '@/assets/sass/style.scss';

import '@/assets/css/custom.css';

Vue.config.productionTip = false

import VueVideoPlayer from 'vue-video-player';
Vue.use(VueVideoPlayer);

Vue.use(bootstrap);

new Vue({
  render: h => h(App),
}).$mount('#app')
