<template>
    <div class="row">
        <div class="col-12 position-relative">
            <NavBar class="position-absolute bottom-0 start-0"></NavBar>
        </div>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';

export default {
    name: "HeaderBar",
    components:{
        NavBar,
    },
    data() {
        return {
            loaded: false,
        };
    },
    mounted() {
        
    },
    updated() {
       
    },
    watch: {

    },
}
</script>