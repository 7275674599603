<template>
    <div>
        <a name="our_service">
            <h2 class="fs-1">บริการของเรา</h2>
        </a>
        <div class="row">
            <div class="col-6">
                <img src="@/assets/4.png" class="img-fluid"/>
            </div>
            <div class="col-6">
                <img src="@/assets/5.png" class="img-fluid"/>
            </div>
            <div class="col-6">
                <img src="@/assets/6.png" class="img-fluid"/>
            </div>
            <div class="col-6">
                <img src="@/assets/7.png" class="img-fluid"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RecommendFuntion",
    data() {
        return {
            loaded: false,
        };
    },
    mounted() {
    
    },
    watch: {

    },
}
</script>