<template>
    <div class="row g-0 bg-dark text-light px-3 py-3">
        <a name="contact_us"></a>
        <div class="col-lg-6 col-md-12 text-center-div">
            <div class="row g-0">
                <div class="col-12"><h3 class="fs-6">เปิดทำการ วันจันทร์-วันเสาร์ 9.00 น.- 18.00 น.</h3></div>
                <div class="col-12"><h3 class="fs-6">(ปิดให้บริการวันอาทิตย์และวันหยุดนักขัตฤกษ์)</h3></div>
                <div class="col-12 mt-3"><h3 class="fs-5"><img src="@/assets/phone-call.png" class="icon-by-size"/> 065-635-2265 </h3></div>
                <div class="col-12"><h3 class="fs-5"><img src="@/assets/email.png" class="icon-by-size"/> scc168.cargo@gmail.com</h3></div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 px-3 py-3">
            <div class="row">
                <div class="col-3"></div>
                <div class="col-6"><img src="@/assets/lineqr.png" class="img-fluid" /></div>
                <div class="col-3"></div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactUs",
    data() {
        return {
        };
    },
    mounted() {

    },
    watch: {

    },
}
</script>