<template>
    <div class="row">
        <a name="warehouse"></a>
        <div class="col-12">
            <img src="@/assets/map.png" class="img-fluid"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "WareHouse",
    data() {
        return {
            loaded: false,
        };
    },
    mounted() {
    
    },
    watch: {

    },
}
</script>